<template lang="pug">
	.card.card-custom.gutter-b
		.card-header
			.card-title
				.card-label Users
			.card-toolbar
		.card-body
			form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
				a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
					span Filters
					i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
				b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
					.row.filters-form-row
						.form-row-main.col-auto.mr-auto(style="flex:1;")
							.form-group
								label Search
								input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword", v-debounce:300ms="loadData")
							.form-group.no-label
								button.btn.btn-primary(type="button", @click="loadData()") Go
						.form-row-end.col-auto
							.form-group.no-label
								router-link.btn.btn-success(:to="{name:'user-add'}")
									i.flaticon2-plus
									| Add

			p.clearfix &nbsp;

			table.table.table-bordered
				thead
					tr
						th ID
						th Name
						th Email
						th Actions
				tbody
					tr(v-if="records.length===0")
						td(colspan="5") No matching records were found
					tr(v-for="r in records", :key="r.id")
						td {{ r.id }}
						td {{ r.first_name }} {{ r.last_name }}
						td {{ r.email }}
						td.actions
							router-link.btn.btn-action.btn-secondary(:to="{name:'user-edit', params: {id:r.id}}")
								i.la.la-pen
							.btn.btn-action.btn-outline-danger()
								i.la.la-trash

			paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
	name: 'UserList',
	data(){
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: 1
			},
			records: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Users' }
		]);
	},
	methods: {
		async loadData(){
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
			};
			try {
				let resp = await this.$api.get('/users/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},
	created(){
		this.loadData();
	}

}
</script>
